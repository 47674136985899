<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="data-list-list-view" class="data-list-container">

    <vs-table ref="table" multiple v-model="selected" :max-items="itemsPerPage" search :data="admins">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
       
          <router-link
            class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
            to="/admins/new"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">إضافة مشرف</span>
          </router-link>
      </div>

      <template slot="thead">
        <vs-th sort-key="username">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="can_update_prices">الدور</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="admins.data"   v-for="(user, index) in data" :key="index">
            
            <vs-td>
              <p class="product-name font-medium truncate"> {{user.username}} </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{user.phone}}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">
                <span v-for="role in user.roles" :key="role">
                  {{`[${role.display_name}]`}}
                </span>
              </p>
            </vs-td>

            <vs-td>
              <vs-switch val="html" v-model="user.is_active" @change="setBan(user.id,user.is_active)" >
                
              </vs-switch>
            </vs-td>

          </vs-tr>
          
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="getData(current_Page)"
    ></vs-pagination>
  </div>
</template>

<script>
import moduleUser from '@/store/users/moduleUser.js'


export default {
  
  data() {
    return {
      selected: [],
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      popupActive: false,
      text:"",
      admins: [],
      

    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },


    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.admins.length
    }
  },
  methods: {
    getData(page){
      this.$store.dispatch('user/fetchAdmins',page)
      .then((response)=>{
        this.admins = response.data.admins.data;
        this.itemsPerPage = response.data.admins.per_page;
        this.totalItems = response.data.admins.total;
        this.lastPage = response.data.admins.last_page;
        this.$vs.loading.close()
      })
    },
    setBan(id,ban){
      var bodyFormData = new FormData();
      bodyFormData.set('id',id);
      bodyFormData.set('status',ban? 1: 0);

      this.$store.dispatch('user/changeStatus', bodyFormData)
      .then(()=>{
        this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل حالة المستخدم بنجاح",
            color: "success",
          });
          // this.getData(1);
      })
      .catch(()=>{
         this.$vs.notify({
                title: "حدث خطأ",
                text: "يرجى اعادة المحاولة",
                color: "danger",
              });
      })
    }
   
    
  },
created() {
    if(!moduleUser.isRegistered){
      this.$store.registerModule("user",moduleUser);
      moduleUser.isRegistered = true;
    }
    this.getData(1);

  },
  mounted() {
    this.$vs.loading()
    this.isMounted = true;
  }
}
</script>








<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }
    .contest-picture{
      max-width:100px;
      max-height:100px
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
